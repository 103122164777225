import React from 'react'
import Layout from '../components/Layout'
import Phone from '../images/phone.svg'
import Message from '../images/message.svg'
import Address from '../images/address.svg'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
// import Arrow from '../images/arrow.svg'
// import Pencil from '../images/pencil.svg'
import styled from 'styled-components'
import SEO from '../components/SEO'
import { StyledForm, StyledText, StyledBox, StyledDescription, StyledHours } from '../components/StyledContact/ContactStyles'
import { StyledMainContent } from '../components/MainStyles/MainContent'
import { motion } from 'framer-motion'
import { fadeAnimation, fadeItem } from '../components/MainStyles/animations'
const Contact = ({ path }) => {
    return (
        <Layout path={path}>
            <SEO
                title="Kontakt"
                description="Skontaktuj się z nami. Telefon: 515 104 483 Email: biuro@art7.pl Adres: Jabłonka, ul. Sobieskiego 5 Godziny otwarcia biura: 08.00 - 16.00"
            />
            <StyledContact>
                <StyledContent>
                    <StyledDescription>
                        <StyledText variants={fadeAnimation} initial="hidden" animate="show">
                            <h2 variants={fadeItem} >Skontaktuj się z nami</h2>
                            <StyledBox variants={fadeItem}>
                                <img src={Phone} alt="" />
                                <p>515 104 483</p>
                            </StyledBox>
                            <StyledBox variants={fadeItem}>
                                <img src={Message} alt="" />
                                <p>biuro@art7.pl</p>
                            </StyledBox>
                            <StyledBox variants={fadeItem}>
                                <img src={Address} alt="" />
                                <p>Jabłonka, ul. Sobieskiego 5</p>
                            </StyledBox>
                            <StyledHours variants={fadeItem}>
                                <p>Godziny otwarcia biura: <span>08.00 - 16.00</span></p>
                            </StyledHours>

                            {/* <StyledSocials variants={fadeItem}>
                                <img src={Facebook} alt="" />
                                <img src={Instagram} alt="" />
                            </StyledSocials> */}
                        </StyledText>
                    </StyledDescription>
                    <StyledForm>
                        <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/wiadomosc-wyslana">
                            {/* <StyledTextField
                                label="Imię i nazwisko"
                                type="text"
                            />
                            <StyledTextField
                                label="Email"
                                type="email"
                            /> */}
                            <input type="hidden" name="form-name" value="contact"></input>
                            <div className="input-data">
                                <input className="inputField" type="text" name="name" id="name" required />
                                <div className="underline"></div>
                                <label htmlFor="name">Imię i Nazwisko</label>
                            </div>
                            <div className="input-data">
                                <input className="inputField" type="text" name="email" id="email" required />
                                <div className="underline"></div>
                                <label htmlFor="email">Email</label>
                            </div>
                            {/* <label>
                                Email
                        <input type="email" />
                                <span className="input-line"></span>
                            </label> */}
                            <div className="text-data">
                                <textarea id="message" name="message" required></textarea>
                                <span className="line1"></span>
                                <span className="line2"></span>
                                <span className="line3"></span>
                                <span className="line4"></span>
                                <label htmlFor="message">Treść wiadomości</label>
                            </div>
                            <div hidden>
                                <input name="bot-field" />
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.06, translateZ: '-43px', }}
                                transition={{ type: 'spring', stiffness: 300, duration: 2, }}
                                type="submit" className="send-input">
                                wyślij
                                {/* <img src={Arrow} alt="" className="input-arrow" /> */}
                            </motion.button>
                        </form>
                    </StyledForm>
                </StyledContent>
            </StyledContact>
        </Layout >
    )
}

const StyledContact = styled.section`
    min-height: 91vh;
    background: linear-gradient(267.37deg, #ECEBEE 1.05%, rgba(250, 250, 250, 0.06) 122.76%);
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ theme }) => theme.media.tablet} {
        min-height: 100vh;
    }
`
const StyledContent = styled(StyledMainContent)`
    margin: 5rem 15rem;
    width: 100%;
    ${({ theme }) => theme.media.tablet} {
        margin: 10rem auto 5rem auto;
        flex-direction: column;
    }
    ${({ theme }) => theme.media.phone} {
        margin: 5rem auto;

    }

`




const StyledSocials = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0 10rem 0;
    width: 50rem;
    img {
        width: 5rem;
        margin: 0 1.2rem;
    }
    ${({ theme }) => theme.media.tablet} {
        padding: 0;
        padding-top: 2rem;
    }
    ${({ theme }) => theme.media.phone} {
        width: 80vw;
        margin: 0 auto;
    }

`

export default Contact
