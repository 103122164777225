import styled from 'styled-components'
import { StyledMainText, StyledMainDescription } from '../MainStyles/MainContent'

import { motion } from 'framer-motion'

export const StyledForm = styled.div`
    margin: 5rem 0;
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    background: ${({ theme }) => theme.color.white};
    border-radius: 14px;
    width: 66rem;
    height: 68rem;

    ${({ theme }) => theme.media.phone} {
        width: 90vw;
        margin: 0 auto 5rem auto;
    }
    form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 50rem;
        ${({ theme }) => theme.media.phone} {
        width: 36rem;
        }

    }

    /* label {
        margin: 2rem 0;
        font-size: 1.7rem;
        font-weight: 500;
        .input-line {
        border-radius: 10rem;
        height: 4px;
        background:${({ theme }) => theme.color.gray};
        width: 50rem;
        }
    } */

    textarea {
        padding: 2rem;
        border: none;
        border-radius: 4px;
        border: 4px solid ${({ theme }) => theme.color.gray};
        width: 100%;
        height: 18rem;
        font-size: 2rem;
        resize: none;
        transition: border .3s ease;
        /* :focus, :valid {
            border: 4px solid ${({ theme }) => theme.color.blue};
        } */
        :focus ~ label, :valid ~ label{
            padding: 0 1rem;
            top: -5%;
            background: white;
            left: 5%;
            transform: translate(0%);
        /* transform: translateY(-30px); */
        font-size: 1.7rem;
        color:${({ theme }) => theme.color.blue};
    }
    }
    .line1 {
        bottom: 0;
    }
    .line2 {
        top: 0;
        :before {
            /* transition-delay: .4s !important; */
        }
    }
    .line1, .line2 {
        position: absolute;
        left: 0;
        height: 4px;
        width: 100%;
        :before {
            transition: transform .2s ease-out;
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            background:${({ theme }) => theme.color.blue};
            transform: scaleX(0);
        }
    }
    .line3 {
        left: 0;
    }
    .line4 {
        right: 0;
    }
    .line3, .line4 {
        position: absolute;

        /* top: 100%;
        transform: translateY(-100%); */
        transform: scaleY(0);
        height: 100%;
        width: 4px;
        background:${({ theme }) => theme.color.blue};
        transition: all .2s ease-out;

    }
    textarea:focus ~ .line1:before,
    textarea:focus ~ .line2:before,
    textarea:valid ~ .line1:before,
    textarea:valid ~ .line2:before
     {
        transform: scaleX(1);
    }
    textarea:focus ~ .line3,
    textarea:valid ~ .line3,
    textarea:focus ~ .line4,
    textarea:valid ~ .line4 {
        transform: scaleY(1);
    }

    .send-input {
        position: relative;
        background: ${({ theme }) => theme.color.blue};
        border-radius: 4px;
        color: ${({ theme }) => theme.color.white};
        font-size: 2rem;
        padding: 0.6rem 8rem 0.9rem 8rem;
        margin: 3rem 0;
        font-variant: small-caps;
        box-shadow: 0px 4px 15px rgba(0, 0, 255, 0.23);
        cursor: pointer;
        border: none;
        transform: perspective(800px);
    }
/*
    .input-arrow {
        position: absolute;
        right: 5rem;
        top: 50%;
        transform: translateY(-50%);
        transition: transform .3s ease-out;
        width: 1.4rem;
    }
    .send-input:hover .input-arrow {
        transform: translate(30%, -50%);
    } */
    .text-data {
        position: relative;
        margin: 2rem 0;
        height: 18rem;
        width: 100%;
        label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all .3s ease;
            font-size: 1.9rem;
            pointer-events: none;
        }
    }

    .input-data {
        height: 4rem;
        width: 100%;
        position: relative;
        margin: 2rem 0;
        label {
        transition: all .3s ease;
        font-size: 2rem;
        position: absolute;
        bottom: 1rem;
        left: 0;
        pointer-events: none;
    }
    }
    .inputField {
        font-family: 'Poppins';
        height: 100%;
        width: 100%;
        border: none;
        font-size: 2rem;
        border-bottom: 4px solid ${({ theme }) => theme.color.gray};
    }
    .inputField:focus ~ label, .inputField:valid ~ label{
        transform: translateY(-30px);
        font-size: 1.7rem;
        color:${({ theme }) => theme.color.blue};
    }

    .underline {
        position: absolute;
        bottom: 0px;
        height: 4px;
        width: 100%;
        :before {
            transition: transform .3s ease;
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            background:${({ theme }) => theme.color.blue};
            transform: scaleX(0);
        }
    }

    .inputField:focus ~ .underline:before, .inputField:valid ~ .underline:before {
        transform: scaleX(1);
    }
`

export const StyledText = styled(StyledMainText)`
    padding: 0;
    padding-right: 4rem;
    ${({ theme }) => theme.media.tablet} {
        padding: 0;
        align-items: flex-start;
    }
`

export const StyledBox = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 4.2rem;
    }
    p {
        padding: 2rem 0 2rem 3rem;
        font-size: 2.2rem;
    }
`

export const StyledDescription = styled(StyledMainDescription)`
    margin: 5rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    h2 {
        padding-bottom: 5rem;
        ${({ theme }) => theme.media.phone} {
            font-size:3.6rem;
        padding-bottom: 4rem;
    }
    }
`

export const StyledHours = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6rem 0 2rem 0;
    width: 50rem;
    p {
        font-size: 2.2rem;
    }
    span {
        font-weight: 700;
        ${({ theme }) => theme.media.phone} {
            display: block;
    }
    }
    ${({ theme }) => theme.media.tablet} {
        padding: 6rem 0;
    }
    ${({ theme }) => theme.media.phone} {
        width: 80vw;
        margin: 0 auto;
        padding: 3rem 0;
    }
`